import {mapMutations} from "vuex";

//sections

export default {
  name: "popup",
  props: {
    scroll: {
      type: Boolean,
      default: ()=> false,
    },
    redirect: {
      type: Boolean,
      default: ()=> false,
    },
  },
  components:{
  },
  data(){
    return{

    }
  },
  created() {

  },
  computed:{

  },

  methods:{
    ...mapMutations({
      changeMainPopup: 'home/CHANGE_MAIN_POPUP'
    }),
    closeEmit(){
      this.redirect ? this.$router.push({name: 'main'}).catch(()=>{console.log()}) : this.$emit('change-close');
    }
  }
}