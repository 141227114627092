import {$http} from '@/utils/https'

import {
  CREATE,
  DELETE,
  FETCH_ADMIN_0_1_USER_DATA,
  FETCH_ADMIN_USER_DATA,
  FETCH_USER_DATA,
  UPDATE,
  UPDATE_ADMIN_0_1_USER_DATA,
  UPDATE_ADMIN_0_1_USER_PASSWORD,
  UPDATE_ADMIN_USER_DATA,
  UPDATE_ADMIN_USER_PASSWORD,
  UPDATE_ADMIN_USER_PHOTO,
  UPDATE_USER_DATA,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PHOTO
} from '../action-types';

import {
  LOADING_USER_DATA,
  SET_ADMIN_USER_DATA,
  SET_USER_DATA,
  SET_USER_ROLE,
  UPDATE_USER_DATA_LOADING,
  UPDATE_USER_PASSWORD_LOADING
} from '../mutation-types';


const state = {
  user: null,
  loadingUser: false,
  updateUserLoading: false,
  updatePasswordLoading: false,
  adminUser: null,
  userRole: null,
};

const getters = {
  updatePasswordLoading: state => state.updatePasswordLoading,
  user: state => state.user,
  loadingUser: state => state.loadingUser,
  updateUserLoading: state => state.updateUserLoading,
  adminUser: state => state.adminUser,
  userRole: state => state.userRole
};

const actions = {
  [FETCH_USER_DATA]: async ({commit}) => {
    try {
      commit(LOADING_USER_DATA, true);
      const response = await $http.get('v1/auth/me?include=role');
      commit(SET_USER_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_USER_DATA, false);
    }
  },
  [FETCH_ADMIN_USER_DATA]: async ({commit}, id) => {
    try {
      commit(LOADING_USER_DATA, true);
      const response = await $http.get(`v1/admin-unit/${id}?include=role,district`);
      commit(SET_ADMIN_USER_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_USER_DATA, false);
    }
  },
  [FETCH_ADMIN_0_1_USER_DATA]: async ({commit}, id) => {
    try {
      commit(LOADING_USER_DATA, true);
      const response = await $http.get(`v1/admin-0-1/${id}?include=role,district`);
      commit(SET_ADMIN_USER_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_USER_DATA, false);
    }
  },
  [UPDATE_USER_DATA]: async ({commit}, payload) => {
    try {
      commit(UPDATE_USER_DATA_LOADING, true);
      const response = await $http.put('v1/update-profile', payload);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(UPDATE_USER_DATA_LOADING, false);
    }
  },
  [UPDATE_ADMIN_USER_DATA]: async ({commit}, {payload, id}) => {
    try {
      commit(UPDATE_USER_DATA_LOADING, true);
      const response = await $http.put(`v1/admin-unit/${id}`, payload);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(UPDATE_USER_DATA_LOADING, false);
    }
  },
  [UPDATE_ADMIN_0_1_USER_DATA]: async ({commit}, {payload, id}) => {
    try {
      commit(UPDATE_USER_DATA_LOADING, true);
      return await $http.put(`v1/admin-0-1/${id}`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(UPDATE_USER_DATA_LOADING, false);
    }
  },
  [UPDATE_USER_PASSWORD]: async ({commit}, payload) => {
    try {
      commit(UPDATE_USER_PASSWORD_LOADING, true);
      const response = await $http.post('v1/auth/password/change', payload);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(UPDATE_USER_PASSWORD_LOADING, false);
    }
  },
  [UPDATE_ADMIN_USER_PASSWORD]: async ({commit}, {payload, id}) => {
    try {
      commit(UPDATE_USER_PASSWORD_LOADING, true);
      const response = await $http.put(`v1/admin-unit/${id}/change-password`, payload);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(UPDATE_USER_PASSWORD_LOADING, false);
    }
  },

  [UPDATE_ADMIN_0_1_USER_PASSWORD]: async ({commit}, {payload, id}) => {
    try {
      commit(UPDATE_USER_PASSWORD_LOADING, true);
      return await $http.put(`v1/admin-0-1/${id}/change-password`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(UPDATE_USER_PASSWORD_LOADING, false);
    }
  },
  [UPDATE_USER_PHOTO]: async ({commit}, payload) => {
    try {
      commit(UPDATE_USER_DATA_LOADING, true);
      const response = await $http.post('v1/update-photo', payload);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(UPDATE_USER_DATA_LOADING, false);
    }
  },
  [UPDATE_ADMIN_USER_PHOTO]: async ({commit}, {payload, id}) => {
    try {
      commit(UPDATE_USER_DATA_LOADING, true);
      const response = await $http.post(`v1/admin-unit/${id}/update-photo`, payload);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(UPDATE_USER_DATA_LOADING, false);
    }
  },

  [CREATE]: async ({commit}, payload) => {
    commit(CHANGE_ADDRESS_LOADING, true);
    try {
      const response = await $http.post(`v1/auth/me/address/add`, payload);
      commit(ADD_ITEM_TO_LIST, response.data.data);

      // reset dialog data
      commit(CHANGE_STATUS_DIALOG_EDIT, false)
      commit(CHANGE_EDITED_ITEM, null)
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_ADDRESS_LOADING, false);
    }
  },
  [UPDATE]: async ({commit}, payload) => {

    commit(CHANGE_ADDRESS_LOADING, true);
    try {
      const response = await $http.put(`v1/auth/me/address/update/${payload.id}`, payload);

      commit(REPLACE_ITEM_FROM_LIST, response.data.data);
      commit(CHANGE_STATUS_DIALOG_EDIT, false)
      commit(CHANGE_EDITED_ITEM, null)
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_ADDRESS_LOADING, false);
    }
  },
  [DELETE]: async ({commit}) => {
    commit(CHANGE_ADDRESS_LOADING, true);
    try {
      const response = await $http.delete(`v1/auth/me/address/delete/${state.removedItem.id}`);
      commit(REMOVE_ITEM_FROM_LIST, state.removedItem);
      commit(CHANGE_REMOVED_ITEM, null)
      commit(CHANGE_STATUS_DIALOG_REMOVE, false)
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_ADDRESS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_USER_DATA](state, data) {
    state.user = data;
  },
  [SET_USER_ROLE](state, data) {
    state.userRole = data;
  },
  [SET_ADMIN_USER_DATA](state, data) {
    state.adminUser = data;
  },
  [LOADING_USER_DATA](state, status) {
    state.loadingUser = status;
  },
  [UPDATE_USER_DATA_LOADING](state, status) {
    state.updateUserLoading = status;
  },
  [UPDATE_USER_PASSWORD_LOADING](state, status) {
    state.updatePasswordLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
