import {$http} from '@/utils/https';


import {
  SET_STATISTIC,
  CHANGE_LOADING_STATISTIC,
  CHANGE_LOADING_CLIENT_STATISTIC,
  SET_CLIENT_STATISTIC,
} from '../mutation-types';

import {
  FETCH_STATISTIC,
  FETCH_CLIENT_STATISTIC
} from '../action-types';

const state = {
  data: null,
  isLoading: false,
  clientStatistics: null,
  clientStatisticsLoading: false,
};

const getters = {
  isLoading: state => state.isLoading,
  data: state => state.data,
  clientStatistics: state => state.clientStatistics,
  clientStatisticsLoading: state => state.clientStatisticsLoading,
};

const actions = {
  [FETCH_STATISTIC]: async ({commit}) => {
    commit(CHANGE_LOADING_STATISTIC, true);
    try {
      const response = await $http.get(`v1/statistic`)
      commit(SET_STATISTIC, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_STATISTIC, false);
    }
  },
  [FETCH_CLIENT_STATISTIC]: async ({commit}, query) => {
    commit(CHANGE_LOADING_CLIENT_STATISTIC, true);
    try {
      const response = await $http.get(`v1/generic-statistic?t=1${(query && Object.keys(query).length) ? '&'+new URLSearchParams(query).toString() : ''}`)
      commit(SET_CLIENT_STATISTIC, response.data.data);
    } catch (e) {
      console.log(e)
      // throw e;
    } finally {
      commit(CHANGE_LOADING_CLIENT_STATISTIC, false);
    }
  }
};

const mutations = {

  [SET_STATISTIC](state, data) {
    state.data = data;
  },
  [CHANGE_LOADING_STATISTIC](state, status) {
    state.isLoading = status;
  },
  [SET_CLIENT_STATISTIC](state, data) {
    state.clientStatistics = data;
  },
  [CHANGE_LOADING_CLIENT_STATISTIC](state, status) {
    state.clientStatisticsLoading = status;
  },

};
export default {
  state,
  getters,
  actions,
  mutations,
};
