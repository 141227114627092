import {$http} from '@/utils/https'
import {getRelativeTime} from "@/helpers/functions";
import store from "@/store";

import {
    SET_NOTIFICATIONS_LIST,
    SET_NOTIFICATIONS_LIST_LOADING,
    SET_NOTIFICATIONS_LOADING,
} from '../mutation-types';
import {
    FETCH_NOTIFICATIONS_LIST,
    ARCHIVE_NOTIFICATION
} from '../action-types';


const state = {
    list     : [],
    isListLoading: false,
};

const getters = {
    counter  : state => state.list.length,
    isListLoading: state => state.isListLoading,
    list: state => state.list,
};

const actions = {
    [FETCH_NOTIFICATIONS_LIST]: async ({commit}) => {
        commit(SET_NOTIFICATIONS_LIST_LOADING, true);
        try {
            const response = await $http.get(`v1/notifications?per_page=999&status=new`);
            commit(SET_NOTIFICATIONS_LIST, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(SET_NOTIFICATIONS_LIST_LOADING, false);
        }
    },
    [ARCHIVE_NOTIFICATION]: async ({commit}, notification) => {
        try {
            notification.archiving = true;
            await $http.put('v1/notifications/' + notification.id);
            commit(ARCHIVE_NOTIFICATION, notification.id);
        } catch (e) {
            throw e;
        } finally {
        }
    },
};

const mutations = {

    [SET_NOTIFICATIONS_LIST](state, list) {
        const locale = store.getters.currentLanguage.translationKey;
        state.list =  list.map(el=>{
            const time = new Date(el.date).getTime();
            el.action = getRelativeTime(time, locale);
            el.archiving = false;
            const daysDifference = Math.round((new Date().getTime() - time) / 86400000);
            if (daysDifference < 3) {
                el.warning = 0;
            } else if (daysDifference < 7) {
                el.warning = 1;
            } else {
                el.warning = 2;
            }

            return el;
        });
    },
    [SET_NOTIFICATIONS_LIST_LOADING](state, status) {
        state.isListLoading = status;
    },
    [SET_NOTIFICATIONS_LOADING](state, status) {
        state.isLoading = status;
    },
    [ARCHIVE_NOTIFICATION](state, id) {
        const index = state.list.findIndex(x => x.id === id);
        if (index >= 0) {
            state.list.splice(index, 1);
        }
    },
};
export default {
    state,
    getters,
    actions,
    mutations,
};
