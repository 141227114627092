//auth
export const GET_TOKEN = 'GET_TOKEN';
export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const SING_IN_WITH_GOOGLE = 'SING_IN_WITH_GOOGLE';
//setting
export const GET_LANGUAGE_LIST = 'GET_LANGUAGE_LIST';
export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';
export const GET_CATEGORIES_MENU = 'GET_CATEGORIES_MENU';
export const GET_CATEGORY_FILTERS = 'GET_CATEGORY_FILTERS';
export const GET_VARIABLES = 'GET_VARIABLES';
//header
export const GET_HEADER_SEARCH_TOOLTIP = 'GET_HEADER_SEARCH_TOOLTIP';
//search
export const GET_SEARCH_LIST = 'GET_SEARCH_LIST';
//contacts
export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const GET_CONTACTS = 'GET_CONTACTS';
//faq
export const GET_FAQ_LIST = 'GET_FAQ_LIST';
export const SEND_ASK = 'SEND_ASK';
//home
export const GET_ADVANTAGE_LIST = 'GET_ADVANTAGE_LIST';
export const GET_MAIN_SLIDER = 'GET_MAIN_SLIDER';
//config
export const GET_CONFIG = 'GET_CONFIG';
//products
export const GET_SLIDER_PRODUCTS = 'GET_SLIDER_PRODUCTS';
//requisites
export const CHECK_AVAILABILITY_PROPS = 'CHECK_AVAILABILITY_PROPS';
//basket
export const CHECK_PROMO_CODE = 'CHECK_PROMO_CODE';
export const RESERVED_REQUISITES = 'RESERVED_REQUISITES';
//props selection
export const GET_PROPS_SELECTION = 'GET_PROPS_SELECTION';
export const CHECK_COUNT_PRODUCTS = 'CHECK_COUNT_PRODUCTS';
export const GET_ALL_FILTERS = 'GET_ALL_FILTERS';
//category
export const GET_LIST = 'GET_LIST';
export const GET_CUSTOM_BREADCRUMBS = 'GET_CUSTOM_BREADCRUMBS';
export const GET_LIST_CATEGORY = 'GET_LIST_CATEGORY';
//card
export const GET_PRODUCT = 'GET_PRODUCT';

//personal
//setting
export const FETCH_USER_DATA = 'FETCH_USER_DATA';
//profile
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const UPDATE_ADMIN_0_1_USER_PASSWORD = 'UPDATE_ADMIN_0_1_USER_PASSWORD';
export const LINK_WITH_GOOGLE = 'LINK_WITH_GOOGLE';
export const UPDATE_USER_PHOTO = 'UPDATE_USER_PHOTO';
//orders
export const GET_ORDERS_LIST = 'GET_ORDERS_LIST';
export const CANCEL_ORDER = 'CANCEL_ORDER';
//history
export const GET_HISTORY_LIST = 'GET_HISTORY_LIST';
//favorites
export const GET_FAVORITES_LIST = 'GET_FAVORITES_LIST';
export const ADD_PRODUCT_TO_FAVOURITES = 'ADD_PRODUCT_TO_FAVOURITES';
export const REMOVE_PRODUCT_IN_FAVORITE = 'REMOVE_PRODUCT_IN_FAVORITE';

export const GET_FILTERS = 'GET_FILTERS';
export const GET_ITEM = 'GET_ITEM';
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const QUICK_ORDER = 'QUICK_ORDER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const CREATE_TRANSACTION = 'CREATE_TRANSACTION';

export const NOVA_POSHTA_GET_TOWNS = 'NOVA_POSHTA_GET_TOWNS';
export const NOVA_POSHTA_GET_DEPARTMENS = 'NOVA_POSHTA_GET_DEPARTMENS';

//admin
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESTORE_PASSWORD = 'RESTORE_PASSWORD';
export const FETCH_ADMIN_LIST = 'FETCH_ADMIN_LIST';
export const FETCH_ADMIN_0_LIST = 'FETCH_ADMIN_0_LIST';
export const ADD_REVIEW_IMAGE = 'ADD_REVIEW_IMAGE';
export const FETCH_IMAGE_LIST = 'FETCH_IMAGE_LIST';
export const UPDATE_REVIEW_IMAGE = 'UPDATE_REVIEW_IMAGE';
export const CREATE_ADMIN = 'CREATE_ADMIN';
export const CREATE_ADMIN_0_1 = 'CREATE_ADMIN_0_1';
export const FETCH_ADMIN_USER_DATA = 'FETCH_ADMIN_USER_DATA';
export const FETCH_ADMIN_0_1_USER_DATA = 'FETCH_ADMIN_0_1_USER_DATA';
export const UPDATE_ADMIN_USER_DATA = 'UPDATE_ADMIN_USER_DATA';
export const UPDATE_ADMIN_0_1_USER_DATA = 'UPDATE_ADMIN_0_1_USER_DATA';
export const UPDATE_ADMIN_USER_PHOTO = 'UPDATE_ADMIN_USER_PHOTO';
export const UPDATE_ADMIN_USER_PASSWORD = 'UPDATE_ADMIN_USER_PASSWORD';
export const FETCH_DISTRICT_LIST = 'FETCH_DISTRICT_LIST';
export const FETCH_PARENT_ADMIN_LIST = 'FETCH_PARENT_ADMIN_LIST';
export const FETCH_FILTER_LIST = 'FETCH_FILTER_LIST';
export const CREATE_OBJECT = 'CREATE_OBJECT';
export const UPDATE_OBJECT = 'UPDATE_OBJECT';
export const ASSIGN_OBJECT = 'ASSIGN_OBJECT';
export const FETCH_CHECKER_NOTIFICATIONS = 'FETCH_CHECKER_NOTIFICATIONS';
export const FETCH_ADMIN_OBJECTS = 'FETCH_ADMIN_OBJECTS';
export const FETCH_ADMIN_CHILDREN_BY_SUPERADMIN = 'FETCH_ADMIN_CHILDREN_BY_SUPERADMIN';
export const FETCH_ADMIN_CHILDREN_BY_ADMIN_0 = 'FETCH_ADMIN_CHILDREN_BY_ADMIN_0';
export const FETCH_SUPER_ADMIN_LIST = 'FETCH_SUPER_ADMIN_LIST';
export const DELETE_ADMIN_0_1 = 'DELETE_ADMIN_0_1';

//emergency objects
export const GET_EMERGENCY_MARKERS = 'GET_EMERGENCY_MARKERS';

//client
export const GET_MARKERS = 'GET_MARKERS';
export const GET_PAGE = 'GET_PAGE';
export const GET_MARKER = 'GET_MARKER';
export const GET_MARKER_FILTERS = 'GET_MARKER_FILTERS';
export const GET_CRITERIAS = 'GET_CRITERIAS';
export const GET_CRITERIAS_SAMPLE = 'GET_CRITERIAS_SAMPLE';
export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_REGISTER = 'SEND_REGISTER';
export const GET_MARKER_REVIEW = 'GET_MARKER_REVIEW';
export const SEND_COMPLAIN = 'SEND_COMPLAIN';
export const SEND_MARKER_REVIEW = 'SEND_MARKER_REVIEW';
export const FETCH_CLIENT_STATISTIC = 'FETCH_CLIENT_STATISTIC';

//notifications
export const FETCH_NOTIFICATIONS_LIST = 'FETCH_NOTIFICATIONS_LIST';
export const ARCHIVE_NOTIFICATION = 'ARCHIVE_NOTIFICATION';

//statistic
export const FETCH_STATISTIC = 'FETCH_STATISTIC';
export const GET_MARKERS_MAP = 'GET_MARKERS_MAP';
export const GET_MAP_CHUNKS = 'GET_MAP_CHUNKS';
export const GET_CRITERIAS_GROUP = 'GET_CRITERIAS_GROUP';
export const GET_DOCUMENT_LIST = 'GET_DOCUMENT_LIST';
export const EXPORT_EVALUATE = 'EXPORT_EVALUATE';
export const IMPORT_EVALUATE = 'IMPORT_EVALUATE';
export const GET_DOCUMENT_FILTERS = 'GET_DOCUMENT_FILTERS';
