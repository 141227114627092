import {mapMutations, mapGetters} from "vuex";
export default {
  name: "mapControlButtons",
  computed: {
    ...mapGetters({
      controls  : 'mapControlButtons/controls',
      activeControlSlug  : 'mapControlButtons/activeControlSlug',
    }),
  },
  methods: {
    ...mapMutations({
      changeControl:'mapControlButtons/CHANGE_MAP_CONTROL',
      dropFilter: 'system/DROP_FILTER'
    }),
    change(control){
      this.$emit('control-changed', control.slug)
      this.changeControl(control)
      if(control.slug === 'search' && this.$route.name === 'search'){
        this.dropFilter(true)
        this.$router.push({name: 'main'}).catch(()=>{console.log()})
      } else if(this.$route.name !== control.slug){
        this.$router.push({name: control.slug}).catch(()=>{console.log()})
      }
    },
  }
}