import {$http} from '@/utils/https';
// import {globalLoader, fixBody} from '@/helpers/variables/index';
import {
  SET_TOKEN,
  REMOVE_TOKEN,
  CHANGE_LOADING,
  SET_LOGIN_DATA,
} from '../mutation-types';
import {
  GET_TOKEN,
  LOGOUT,
  LOGIN,
  RESET_PASSWORD,
  RESTORE_PASSWORD,
} from '../action-types';

const state = {
  loginData: null,
  isAuthenticated: false,
  isLoading: false
};

const getters = {
  isAuthenticated: state => state.isAuthenticated,
  isLoading: state => state.isLoading,
  loginData: state => state.loginData,
};

const actions = {
  [GET_TOKEN]: async ({commit}, payload) => {
    commit(CHANGE_LOADING, true);
    try {
      const result = await $http.post('v1/auth/login', payload);
      commit(SET_LOGIN_DATA, result.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [RESET_PASSWORD]: async ({commit}, payload) => {
    commit(CHANGE_LOADING, true);
    try {
      return await $http.post('v1/auth/password/reset', payload);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [RESTORE_PASSWORD]: async ({commit}, payload) => {
    commit(CHANGE_LOADING, true);
    try {
      return await $http.post('v1/auth/password/restore', payload);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [LOGIN]: async ({commit}, payload) => {
    commit(CHANGE_LOADING, true);
    try {
      const result = await $http.post('v1/auth/login2fa', payload);
      commit(SET_TOKEN, result.data.accessToken);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [LOGOUT]: async ({commit}) => {
    try {

      return await $http.post('v1/logout');
    } catch (e) {
      throw e;
    } finally {
      commit(REMOVE_TOKEN);
    }
  },
};

const mutations = {
  [SET_LOGIN_DATA](state, data){
    state.loginData = data
  },
  [SET_TOKEN](state, token) {
    state.isAuthenticated = true;
    localStorage.setItem('user_token', token);
  },
  [REMOVE_TOKEN](state) {
    localStorage.removeItem('user_token');
    state.isAuthenticated = false;
    setTimeout(() => {
      location.reload()
    }, 200)

  },
  [CHANGE_LOADING](state, status) {
    state.isLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
