<template>
  <div id="app" class="app">
    <div class="app-container">
      <div class="wrapper">
        <div class="wrapper-top">
          <main-header v-if="!isAdminRoute"/>
          <transition name="component-fade" mode="out-in">
            <router-view/>
          </transition>
          <transition name="component-fade" mode="out-in">
            <div v-if="cookie" class="cookie">
              <div class="cookie-wrap">
                <div class="cookie-close" @click="declineCookie">
                  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.79688 9.79688L23.2031 23.2031M23.2031 9.79688L9.79688 23.2031L23.2031 9.79688Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                </div>
                <div class="cookie-block">
                  <div class="cookie-container">
                    <div class="cookie-block-wrap">
                      <h6>
                        {{$t('cookieTitle')}} <router-link :to="$localize({name: 'privacy'})">{{$t('privacyPolicy')}}</router-link>!
                      </h6>
                      <div class="cookie-block-btn">
                        <div>
                          <main-button :small="true" :white="true" @change-event="acceptCookie" :label="$t('cookieAccept')"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div class="wrapper-bottom" v-if="!isAdminRoute">
          <main-footer/>
        </div>
      </div>
    </div>
    <hidden/>
  </div>
</template>

<style src="@/assets/scss/main.scss" lang="scss"></style>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import Hidden from '@/components/hidden/index.vue';
import MainHeader from '@/components/header/index.vue';
import MainFooter from '@/components/footer/index.vue';
import {nanoid} from 'nanoid'
import {isMobile, isIPad} from '@/helpers/variables'
import {fixArc, defineUserRole} from '@/helpers/functions'


export default {
  name: 'app',
  components: {
    Hidden,
    MainHeader,
    MainFooter
  },
  data(){
    return{
      cookie: false,
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: `auth/isAuthenticated`,
      variables: `setting/variables`,
      user: 'profile/user',
    }),
    isAdminRoute() {
      return this.$route.path.indexOf('/admin/') >= 0
    }
  },
  created() {
    if(!localStorage.getItem('accept')){
      this.cookie = true
    }

    localStorage.getItem('device_id') ? '' : localStorage.setItem('device_id', nanoid())

    if (localStorage.getItem('user_token')) {
      this.fetchUserData().then(()=>{
        if (this.user && this.user.role) {
          let role = defineUserRole(this.user)
          this.setUserRole(role)
        }
      })
    }

  },
  mounted() {
    if (!isMobile.any()) {
      document.querySelector('body').classList.add('hover');
    }
    if (!isIPad.iOS()) {
      document.querySelector('body').classList.add('iPad');
    }

    fixArc();
  },
  methods: {
    ...mapMutations({
      setUserRole: 'profile/SET_USER_ROLE'
    }),
    ...mapActions({
      fetchUserData: 'profile/FETCH_USER_DATA',
    }),
    acceptCookie(){
      localStorage.setItem('accept', 'true')
      this.cookie = false
    },
    declineCookie(){
      if(localStorage.getItem('accept')){
        localStorage.removeItem('accept')
      }
      this.cookie = false
    }
  }
}
</script>
