import {$http} from '@/utils/https'
import {
  CHANGE_LOADING_LIST,
  CHANGE_MAIN_POPUP,
  SET_LIST,
  SET_CRITERIAS,
  SET_CRITERIAS_SAMPLE,
  SET_CRITERIAS_GROUP,
  CHANGE_EMAIL_LOADING,
  CHANGE_COMPLAIN_LOADING,
  CHANGE_REGISTER_LOADING,
  CHANGE_FEEDBACK_LOADING,
  CHANGE_LOADING_PAGE,
  SET_COMPLAIN_OBJECT,
  SET_PAGE,
  SET_DOCUMENT_LIST,
  SET_CONTACTS, GET_FILTERS_LOADING, SET_FILTERS, SET_LIST_LENGTH, SET_DOCUMENT_FILTERS
} from '../mutation-types';
import {
  GET_CONTACTS, GET_FILTERS,
  GET_LIST,
  GET_CRITERIAS,
  GET_CRITERIAS_GROUP,
  GET_CRITERIAS_SAMPLE,
  SEND_EMAIL,
  SEND_COMPLAIN,
  SEND_REGISTER,
  SEND_FEEDBACK,
  GET_PAGE,
  GET_DOCUMENT_LIST, GET_DOCUMENT_FILTERS
} from '../action-types';


const state = {
  list: [],
  listLoading: false,
  contacts: null,
  filters: [],
  filterLoading: false,
  criterias: null,
  criteriasGroup: null,
  criteriasSample: null,
  mainPopup: false,
  sendEmailLoading: false,
  registerLoading: false,
  complainLoading: false,
  feedbackLoading: false,
  page: null,
  pageLoading: false,
  complainObject: null,
  documentList: null,
  documentFilters: null,
};

const getters = {
  contacts: state => state.contacts,
  list: state => state.list,
  listLoading: state => state.listLoading,
  filters: state => state.filters,
  filterLoading: state => state.filterLoading,
  criterias: state => state.criterias,
  criteriasSample: state => state.criteriasSample,
  mainPopup: state => state.mainPopup,
  sendEmailLoading: state => state.sendEmailLoading,
  registerLoading: state => state.registerLoading,
  complainLoading: state => state.complainLoading,
  feedbackLoading: state => state.feedbackLoading,
  page: state => state.page,
  pageLoading: state => state.pageLoading,
  complainObject: state => state.complainObject,
  criteriasGroup: state => state.criteriasGroup,
  documentList: state => state.documentList,
  documentFilters: state => state.documentFilters,
};

const actions = {
  [GET_LIST]: async ({commit}) => {
    commit(CHANGE_LOADING_LIST, true);
    try {
      const response = await $http.get(`v1/product?limit=${4}&main_page=true`);

      commit(SET_LIST, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_LIST, false);
    }
  },
  [GET_PAGE]: async ({commit}, slug) => {
    commit(CHANGE_LOADING_PAGE, true);
    try {
      const response = await $http.get(`v1/pages/${slug}`);
      commit(SET_PAGE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_PAGE, false);
    }
  },
  [GET_CRITERIAS]: async ({commit}) => {
    try {
      const response = await $http.get(`v2/criteria_groups?include=categories,criterias,criteria_groups`);
      commit(SET_CRITERIAS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_CRITERIAS_GROUP]: async ({commit}, slug) => {
    try {
      const response = await $http.get(`v2/criteria_groups/${slug}?include=categories,criterias,criteria_groups`);
      commit(SET_CRITERIAS_GROUP, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },

  [SEND_EMAIL]: async ({commit}, payload) => {
    commit(CHANGE_EMAIL_LOADING, true);
    try {
      return await $http.post(`v1/auth/email/confirm`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_EMAIL_LOADING, false);
    }
  },
  [SEND_REGISTER]: async ({commit}, payload) => {
    commit(CHANGE_REGISTER_LOADING, true);
    try {
      return await $http.post(`v1/auth/register`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_REGISTER_LOADING, false);
    }
  },
  [SEND_FEEDBACK]: async ({commit}, payload) => {
    commit(CHANGE_FEEDBACK_LOADING, true);
    try {
      return await $http.post(`v1/feedback`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_FEEDBACK_LOADING, false);
    }
  },
  [SEND_COMPLAIN]: async ({commit}, {id,payload}) => {
    commit(CHANGE_COMPLAIN_LOADING, true);
    try {
      return await $http.post(`v1/units/${id}/complain`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_COMPLAIN_LOADING, false);
    }
  },
  [GET_CRITERIAS_SAMPLE]: async ({commit}, slug) => {
    try {
      const response = await $http.get(`v2/criterias/${slug}?include=questions`);
      commit(SET_CRITERIAS_SAMPLE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_CONTACTS]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/contact`);
      commit(SET_CONTACTS, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_DOCUMENT_LIST]: async ({commit}, query) => {
    try {
      const response = await $http.get(`v1/documents?include=country,language,district&${query}`);
      commit(SET_DOCUMENT_LIST, response.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_DOCUMENT_FILTERS]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/documents/filter`);
      commit(SET_DOCUMENT_FILTERS, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_FILTERS]: async ({commit}) => {
    try {
      commit(GET_FILTERS_LOADING, true);
      const response = await $http.get(`v1/category?main_page=true`);
      commit(SET_FILTERS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(GET_FILTERS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_DOCUMENT_LIST](state, list) {
    state.documentList = list;
  },
  [SET_DOCUMENT_FILTERS](state, list) {
    state.documentFilters = list;
  },
  [SET_LIST](state, list) {
    state.list = list;
  },
  [SET_PAGE](state, list) {
    state.page = list;
  },
  [SET_CRITERIAS](state, list) {
    state.criterias = list;
  },
  [SET_CRITERIAS_GROUP](state, list) {
    state.criteriasGroup = list;
  },
  [SET_CRITERIAS_SAMPLE](state, list) {
    state.criteriasSample = list;
  },
  [SET_CONTACTS](state, data) {
    state.contacts = data;
  },
  [CHANGE_MAIN_POPUP](state, status) {
    state.mainPopup = status;
  },
  [CHANGE_FEEDBACK_LOADING](state, status) {
    state.feedbackLoading = status;
  },
  [CHANGE_COMPLAIN_LOADING](state, status) {
    state.complainLoading = status;
  },
  [CHANGE_EMAIL_LOADING](state, status) {
    state.sendEmailLoading = status;
  },
  [CHANGE_REGISTER_LOADING](state, status) {
    state.registerLoading = status;
  },
  [CHANGE_LOADING_LIST](state, status) {
    state.listLoading = status;
  },
  [CHANGE_LOADING_PAGE](state, status) {
    state.pageLoading = status;
  },
  [SET_LIST_LENGTH](state, count) {
    state.count = count;
  },
  [SET_FILTERS](state, list) {
    state.filters = list;
  },
  [SET_COMPLAIN_OBJECT](state, data) {
    state.complainObject = data;
  },
  [GET_FILTERS_LOADING](state, status) {
    state.filterLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
