import {$http} from '@/utils/https'
import {defineUserRole} from "../../helpers/functions";

import {
  FETCH_ADMIN_LIST,
  FETCH_ADMIN_0_LIST,
  CREATE_ADMIN,
  CREATE_ADMIN_0_1,
  FETCH_DISTRICT_LIST,
  FETCH_PARENT_ADMIN_LIST,
  FETCH_FILTER_LIST,
  CREATE_OBJECT,
  UPDATE_OBJECT,
  FETCH_CHECKER_NOTIFICATIONS,
  ASSIGN_OBJECT,
  ADD_REVIEW_IMAGE,
  UPDATE_REVIEW_IMAGE,
  FETCH_IMAGE_LIST,
  FETCH_ADMIN_OBJECTS,
  FETCH_SUPER_ADMIN_LIST,
  FETCH_ADMIN_CHILDREN_BY_SUPERADMIN,
  FETCH_ADMIN_CHILDREN_BY_ADMIN_0,
  DELETE_ADMIN_0_1,
  EXPORT_EVALUATE,
  IMPORT_EVALUATE,
} from '../action-types';

import {
  SET_ADMIN_LIST,
  LOADING_ADMIN_LIST,
  LOADING_ADMIN_CREATE,
  LOADING_DISTRICT_LIST,
  SET_DISTRICT_LIST,
  LOADING_PARENT_ADMIN_LIST,
  SET_PARENT_ADMIN_LIST,
  LOADING_FILTER_LIST,
  LOADING_OBJECT_CREATE,
  LOADING_OBJECT_UPDATE,
  SET_FILTER_LIST,
  SET_EVALUATE_LIST,
  SET_EVALUATE_LIST_NESTED,
  LOADING_CHECKER_NOTIFICATIONS,
  SET_CHECKER_NOTIFICATIONS,
  LOADING_OBJECT_ASSIGN,
  LOADING_ADD_REVIEW_IMAGE,
  LOADING_UPDATE_REVIEW_IMAGE,
  SET_IMAGE_LIST,
  REMOVE_ADMIN_FROM_LIST,
  LOADING_EXPORT_EVALUATE,
  SET_EXPORT_EVALUATE,
  LOADING_IMPORT_EVALUATE,
} from '../mutation-types';
import Vue from "vue";

function decorateAdminItem (data) {
  data.isObjectsLoading = false;
  data.isChildrenLoading = false;
  data.isDeleteLoading = false;
  data.objects = null;
  data.children = null;
  data.userRole = defineUserRole(data)
  return data
}

const state = {
  imageList: null,
  adminList: null,
  adminListLoading: false,
  districts: null,
  districtsLoading: false,
  parentAdminList: null,
  parentAdminListLoading: false,
  createObjectLoading: false,
  evaluateList: null,
  evaluateListNested: null,
  checkerNotifications: null,
  checkerNotificationsLoading: false,
  filter: {
    subdivisions: {
      data: []
    },
    types: {
      data: []
    }
  },
  filterLoading: false,
  adminCreateLoading: false,
  updateObjectLoading: false,
  assignObjectLoading: false,
  loadingAddReviewImage: false,
  loadingUpdateReviewImage: false,
  exportEvaluateLoading: false,
  exportEvaluate: null,
  importEvaluateLoading: false,
};

const getters = {
  adminList: state => state.adminList,
  adminListLoading: state => state.adminListLoading,
  adminCreateLoading: state => state.adminCreateLoading,
  districts: state => state.districts,
  districtsLoading: state => state.districtsLoading,
  parentAdminList: state => state.parentAdminList,
  parentAdminListLoading: state => state.parentAdminListLoading,
  filter: state => state.filter,
  filterLoading: state => state.filterLoading,
  createObjectLoading: state => state.createObjectLoading,
  updateObjectLoading: state => state.updateObjectLoading,
  evaluateList: state => state.evaluateList,
  evaluateListNested: state => state.evaluateListNested,
  checkerNotifications: state => state.checkerNotifications,
  checkerNotificationsLoading: state => state.checkerNotificationsLoading,
  assignObjectLoading: state => state.assignObjectLoading,
  loadingAddReviewImage: state => state.loadingAddReviewImage,
  loadingUpdateReviewImage: state => state.loadingUpdateReviewImage,
  imageList: state => state.imageList,
  exportEvaluateLoading: state => state.exportEvaluateLoading,
  exportEvaluate: state => state.exportEvaluate,
  importEvaluateLoading: state => state.importEvaluateLoading,
};

const actions = {
  [FETCH_ADMIN_LIST]: async ({commit}) => {
    try {
      commit(LOADING_ADMIN_LIST, true);
      const response = await $http.get('v1/admin-unit?include=role,units,review');
      commit(SET_ADMIN_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_ADMIN_LIST, false);
    }
  },

  [FETCH_ADMIN_0_LIST]: async ({commit}) => {
    try {
      commit(LOADING_ADMIN_LIST, true);
      const response = await $http.get('v1/admin-0-1?include=role,units,review');
      commit(SET_ADMIN_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_ADMIN_LIST, false);
    }
  },
  [FETCH_DISTRICT_LIST]: async ({commit}) => {
    try {
      commit(LOADING_DISTRICT_LIST, true);
      const response = await $http.get('v1/districts');
      commit(SET_DISTRICT_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_DISTRICT_LIST, false);
    }
  },
  [FETCH_PARENT_ADMIN_LIST]: async ({commit}, params) => {
    try {
      commit(LOADING_PARENT_ADMIN_LIST, true);

      let query = ``
      if (params) {
        query = Object.keys(params).reduce((acc,key) => {
          return acc + `${key}=${params[key]}`
        },'&')
      }

      const response = await $http.get(`v1/admin-unit?include=role${query}`);
      commit(SET_PARENT_ADMIN_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_PARENT_ADMIN_LIST, false);
    }
  },
  [FETCH_CHECKER_NOTIFICATIONS]: async ({commit}) => {
    try {
      commit(LOADING_CHECKER_NOTIFICATIONS, true);
      const response = await $http.get('v1/checker-notifications');
      commit(SET_CHECKER_NOTIFICATIONS, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_CHECKER_NOTIFICATIONS, false);
    }
  },
  [FETCH_FILTER_LIST]: async ({commit}) => {
    try {
      commit(LOADING_FILTER_LIST, true);
      const response = await $http.get('v1/filter?include=categories,type');
      commit(SET_FILTER_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_FILTER_LIST, false);
    }
  },
  [CREATE_ADMIN]: async ({commit}, payload) => {
    try {
      commit(LOADING_ADMIN_CREATE, true);
      return await $http.post('v1/admin-unit', payload);
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_ADMIN_CREATE, false);
    }
  },

  [CREATE_ADMIN_0_1]: async ({commit}, payload) => {
    try {
      commit(LOADING_ADMIN_CREATE, true);
      return await $http.post('v1/admin-0-1', payload);
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_ADMIN_CREATE, false);
    }
  },
  [CREATE_OBJECT]: async ({commit}, payload) => {
    try {
      commit(LOADING_OBJECT_CREATE, true);
      return await $http.post('v1/unit?include=owner,checker,category,type,photos,criterias,values', payload);
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_OBJECT_CREATE, false);
    }
  },
  [ADD_REVIEW_IMAGE]: async ({commit}, {id, payload}) => {
    try {
      commit(LOADING_ADD_REVIEW_IMAGE, true);
      return await $http.post(`v2/reviews/${id}/photos`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_ADD_REVIEW_IMAGE, false);
    }
  },
  [FETCH_IMAGE_LIST]: async ({commit}, id) => {
    try {
      const response = await $http.get(`v1/reviews/${id}/photos`);
      commit(SET_IMAGE_LIST, response.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [UPDATE_REVIEW_IMAGE]: async ({commit}, photoId) => {
    try {
      commit(LOADING_UPDATE_REVIEW_IMAGE, true);
      return await $http.delete(`v2/reviews/photos/${photoId}`);
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_UPDATE_REVIEW_IMAGE, false);
    }
  },
  [UPDATE_OBJECT]: async ({commit}, {id, payload}) => {
    try {
      commit(LOADING_OBJECT_UPDATE, true);
      return await $http.put(`v1/unit/${id}`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_OBJECT_UPDATE, false);
    }
  },
  [ASSIGN_OBJECT]: async ({commit}, id) => {
    try {
      commit(LOADING_OBJECT_ASSIGN, true);
      return await $http.post(`v1/unit/${id}/update-checker`);
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_OBJECT_ASSIGN, false);
    }
  },
  [FETCH_SUPER_ADMIN_LIST]: async ({commit}) => {
    try {
      commit(LOADING_ADMIN_LIST, true);
      const response = await $http.get('v1/super-admin/admin-units?include=role,units,review');
      commit(SET_ADMIN_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_ADMIN_LIST, false);
    }
  },
  [FETCH_ADMIN_OBJECTS]: async ({commit}, {admin_id, page}) => {
    const response = await $http.get(`v1/units-by-owner/${admin_id}?include=owner,checker,category,type,photos,criterias,values,review,result,district,review_results,criteria_value,disability,criterias&page=${page}&per_page=15`);
    return response.data;
  },
  [FETCH_ADMIN_CHILDREN_BY_SUPERADMIN]: async ({commit}, admin_id) => {
      const response = await $http.get('v1/super-admin/admin-units/' + admin_id + '?include=role,units,review');
      return response.data.data.map(el=>{
        return decorateAdminItem(el);
      });
  },
  [FETCH_ADMIN_CHILDREN_BY_ADMIN_0]: async ({commit}, admin_id) => {
    const response = await $http.get('v1/admin-unit/' + admin_id + '?include=role,units,children');
    return response.data.data.children.data.map(el=>{
      return decorateAdminItem(el);
    });
  },

  [DELETE_ADMIN_0_1]: async ({commit}, admin_id) => {
    const response = await $http.delete('v1/admin-0-1/' + admin_id);
    commit(REMOVE_ADMIN_FROM_LIST, admin_id);
    return response.data.data;
  },
  [EXPORT_EVALUATE]: async ({commit}, id) => {
    try {
      commit(LOADING_EXPORT_EVALUATE, true);
      const response = await $http.get(`v2/units/${id}/review-export-xls`, {responseType: 'blob'});
      commit(SET_EXPORT_EVALUATE, response.data);
      console.log(response);
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_EXPORT_EVALUATE, false);
    }
  },
  [IMPORT_EVALUATE]: async ({commit}, {id, payload}) => {
    try {
      commit(LOADING_IMPORT_EVALUATE, true);
      return  await $http.post(`v2/units/${id}/review-import-xls`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_IMPORT_EVALUATE, false);
    }
  },
};

const mutations = {
  [SET_ADMIN_LIST](state, data) {
    state.adminList =  data.map(el=>{
      return decorateAdminItem(el);
    });
  },
  [LOADING_EXPORT_EVALUATE](state, status) {
    state.exportEvaluateLoading = status;
  },
  [LOADING_IMPORT_EVALUATE](state, status) {
    state.importEvaluateLoading = status;
  },
  [SET_EXPORT_EVALUATE](state, data) {
    state.exportEvaluate = data;
  },
  [REMOVE_ADMIN_FROM_LIST](state, admin_id) {
    const index = state.adminList.findIndex(e => e.id === admin_id);
    Vue.delete(state.adminList, index)
  },
  [LOADING_ADMIN_LIST](state, status) {
    state.adminListLoading = status;
  },
  [SET_CHECKER_NOTIFICATIONS](state, data) {
    state.checkerNotifications = data;
  },
  [SET_IMAGE_LIST](state, data) {
    state.imageList = data;
  },
  [LOADING_CHECKER_NOTIFICATIONS](state, status) {
    state.checkerNotificationsLoading = status;
  },
  [SET_DISTRICT_LIST](state, data) {
    state.districts = data;
  },
  [LOADING_DISTRICT_LIST](state, status) {
    state.districtsLoading = status;
  },
  [SET_PARENT_ADMIN_LIST](state, data) {
    state.parentAdminList = data;
  },
  [LOADING_PARENT_ADMIN_LIST](state, status) {
    state.parentAdminListLoading = status;
  },
  [LOADING_ADD_REVIEW_IMAGE](state, status) {
    state.loadingAddReviewImage = status;
  },
  [LOADING_UPDATE_REVIEW_IMAGE](state, status) {
    state.loadingUpdateReviewImage = status;
  },
  [SET_FILTER_LIST](state, data) {
    state.filter = data;
  },
  [SET_EVALUATE_LIST](state, data) {
    state.evaluateList = null
    state.evaluateList = data;
  },
  [SET_EVALUATE_LIST_NESTED](state, data) {
    state.evaluateListNested = null
    state.evaluateListNested = data;
  },
  [LOADING_FILTER_LIST](state, status) {
    state.filterLoading = status;
  },
  [LOADING_ADMIN_CREATE](state, status) {
    state.adminCreateLoading = status;
  },
  [LOADING_OBJECT_CREATE](state, status) {
    state.createObjectLoading = status;
  },
  [LOADING_OBJECT_UPDATE](state, status) {
    state.updateObjectLoading = status;
  },
  [LOADING_OBJECT_ASSIGN](state, status) {
    state.assignObjectLoading = status;
  },
};


export default {
  state,
  getters,
  actions,
  mutations,
};
