import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter);

const isAuthenticated = (to, from, next) => {
  if (store.state.auth.isAuthenticated) {
    return next();
  }
  return next({ path: "/admin/sign-in" })
};

const routes = [
  {
    path: '/:lang?',
    name: 'main',
    component: () => import('../modules/client/home/index.vue'),
    redirect: '/:lang?/',
    children: [
      {
        path: '/:lang?/',
        name: 'search',
        component: () => import('../modules/client/home/section/promo/tabs/search/index.vue'),
      },
      {
        path: '/:lang?/register',
        name: 'register',
        component: () => import('../modules/client/home/section/register/index.vue'),
      },
      {
        path: '/:lang?/marsrutai',
        name: 'route',
        component: () => import('../modules/client/home/section/promo/tabs/route/index.vue'),
      },
      {
        path: '/:lang?/statistika',
        name: 'statistic',
        component: () => import('../modules/client/home/section/promo/tabs/statistics/index.vue'),
      },
      {
        path: '/:lang?/informuoti',
        name: 'complain',
        component: () => import('../modules/client/home/section/promo/tabs/complain/index.vue'),
      },
    ]
  },
  {
    path: '/:lang?/informacija-savininkams',
    name: 'how',
    component: () => import('../modules/client/how/index.vue')
  },
  {
    path: '/:lang?/apie-projekta',
    name: 'about',
    component: () => import('../modules/client/about/index.vue')
  },
  {
    path: '/:lang?/privatumo-politika',
    name: 'privacy',
    component: () => import('../modules/client/privacy/index.vue')
  },
  {
    path: '/:lang?/pritaikymo-kriterijai-grupes',
    name: 'filters',
    component: () => import('../modules/client/filters/index.vue')
  },
  {
    path: '/:lang?/pritaikymo-kriterijai-grupes/:slug',
    name: 'filtersChildren',
    component: () => import('../modules/client/filters-children/index.vue')
  },
  {
    path: '/:lang?/pritaikymo-kriterijai/:slug',
    name: 'info',
    component: () => import('../modules/client/info/index.vue')
  },
  {
    path: '/:lang?/metodine-informacija',
    name: 'documents',
    component: () => import('../modules/client/documents/index.vue')
  },
  {
    path: '/:lang?/admin/sign-in',
    name: 'sign-in',
    component: () => import('../modules/admin/auth/index.vue'),
  },
  {
    path: '/:lang?/admin/reset-password',
    name: 'reset',
    component: () => import('../modules/admin/reset/index.vue'),
  },
  {
    path: '/:lang?/admin/restore-password',
    name: 'restore',
    component: () => import('../modules/admin/restore/index.vue'),
  },
  {
    path: '/:lang?/admin',
    name: 'admin',
    component: () => import('../modules/admin/index.vue'),
    redirect: '/:lang?/admin/profile',
    beforeEnter: isAuthenticated,
    children: [
      {
        path: '/:lang?/admin/profile',
        name: 'profile',
        component: () => import('../modules/admin/pages/profile/index.vue'),
      },
      {
        path: '/:lang?/admin/administrators-list',
        name: 'admin-list',
        component: () => import('../modules/admin/pages/administrators-units/index.vue'),
      },
      {
        path: '/:lang?/admin/administrators-list/:id',
        name: 'admin-show',
        component: () => import('../modules/admin/pages/administrator-profile/index.vue'),
      },
      {
        path: '/:lang?/admin/administrators-0-list',
        name: 'admin-0-list',
        component: () => import('../modules/admin/pages/administrators-zero/index.vue'),
      },
      {
        path: '/:lang?/admin/administrators-0-list/:id',
        name: 'admin-0-show',
        component: () => import('../modules/admin/pages/administrator-profile/index.vue'),
      },
      {
        path: '/:lang?/admin/super-admin-list',
        name: 'super-admin-list',
        component: () => import('../modules/admin/pages/administrators-super/index.vue'),
      },
      {
        path: '/:lang?/admin/object-list',
        name: 'object-list',
        component: () => import('../modules/admin/pages/all-objects/index.vue'),
      },
      {
        path: '/:lang?/admin/emergency-object-list',
        name: 'emergency-object-list',
        component: () => import('../modules/admin/pages/emergency-objects/index.vue'),
      },
      {
        path: '/:lang?/admin/create-object',
        name: 'create-object',
        component: () => import('../modules/admin/pages/create-object/index.vue'),
      },
      {
        path: '/:lang?/admin/evaluate/:id',
        name: 'evaluate',
        component: () => import('../modules/admin/pages/evaluate/index.vue'),
      },
      {
        path: '/:lang?/admin/object-list/:id',
        name: 'show-object',
        component: () => import('../modules/admin/pages/show-object/index.vue'),
      },
      {
        path: '/:lang?/admin/create-administrator',
        name: 'admin-create',
        component: () => import('../modules/admin/pages/administrator-create/index.vue'),
      },
      {
        path: '/:lang?/admin/notifications',
        name: 'notifications',
        component: () => import('../modules/admin/pages/notifications/index.vue'),
      },
      {
        path: '/:lang?/admin/statistic',
        name: 'statistic-admin',
        component: () => import('../modules/admin/pages/statistic-page/index.vue'),
      },
    ]
  }

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    let el = document.querySelector('.app');
    if (to.path !== from.path) {
      if (savedPosition) {
        return savedPosition
      } else {
        el.scrollTop = 0;

        return {x: 0, y: 0}
      }
    }
  }
});

export default router
