import {$http} from '@/utils/https'
import {
  SET_MARKERS,
  SET_EMERGENCY_MARKERS,
  CHANGE_LOADING_MARKERS,
  CHANGE_LOADING_EMERGENCY_MARKERS,
  SET_MARKER,
  SET_MARKER_REVIEW,
  CHANGE_LOADING_MARKER,
  CHANGE_LOADING_MARKER_REVIEW,
  CLEAR_MARKER_REVIEW,
  CHANGE_LOADING_SEND_MARKER_REVIEW,
  SET_MARKER_FILTERS,
  CHANGE_LOADING_MARKER_FILTERS,
  CLEAR_MARKER,
  SET_LAST,
  SET_MAP_CHUNKS,
  SET_MARKER_LIST,
  SET_FORMATTED_MARKERS,
} from '../mutation-types';
import {
  GET_MARKERS,
  GET_EMERGENCY_MARKERS,
  GET_MARKER,
  GET_MARKER_REVIEW,
  SEND_MARKER_REVIEW,
  GET_MARKER_FILTERS,
  GET_MAP_CHUNKS,
  GET_MARKERS_MAP,
} from '../action-types';
import store from "../index";


const state = {
  markers: null,
  pageCount: null,
  emergencyMarkers: null,
  markersLoading: false,
  emergencyMarkersLoading: false,
  marker: null,
  markerLoading: false,
  markerFilters: null,
  markerFiltersLoading: false,
  markerReview: null,
  markerReviewLoading: false,
  sendMarkerReviewLoading: false,
  mapChunks: [],
  markerList: [],
  formattedMarkers: [],
};

const getters = {
  markers: state => state.markers,
  pageCount: state => state.pageCount,
  emergencyMarkers: state => state.emergencyMarkers,
  markersLoading: state => state.markersLoading,
  emergencyMarkersLoading: state => state.emergencyMarkersLoading,
  marker: state => state.marker,
  markerLoading: state => state.markerLoading,
  markerReview: state => state.markerReview,
  markerReviewLoading: state => state.markerReviewLoading,
  sendMarkerReviewLoading: state => state.sendMarkerReviewLoading,
  markerFilters: state => state.markerFilters,
  markerFiltersLoading: state => state.markerFiltersLoading,
  mapChunks: state => state.mapChunks,
  markerList: state => state.markerList,
  formattedMarkers: state => state.formattedMarkers,
};

const actions = {
  [GET_MARKERS]: async ({commit}, query) => {
    commit(CHANGE_LOADING_MARKERS, true);
    try {
      const response = await $http.get(`v2/units?include=owner,checker,category,disability,type,photos,criterias,result,review,district${(query && Object.keys(query).length) ? '&'+new URLSearchParams(query).toString() : ''}`);
      commit(SET_MARKERS, response.data.data);
      if(response.data.meta){
        commit(SET_LAST, response.data.meta.lastPage);
      }
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_MARKERS, false);
    }
  },
  [GET_MARKERS_MAP]: async ({commit}, url) => {
    try {
      fetch(url).then(async response =>{
        const data = await response.json();
        commit(SET_MARKER_LIST, data);
      })
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_MAP_CHUNKS]: async ({commit}, query) => {
    try {
      const response = await $http.get(`v2/unit-chunks${(query && Object.keys(query).length) ? '?'+new URLSearchParams(query).toString() : ''}`);
      commit(SET_MAP_CHUNKS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_MARKER]: async ({commit}, {id, isMap}) => {
    commit(CHANGE_LOADING_MARKER, true);
    try {
      const response = await $http.get(`v2/units/${id}?include=owner,checker,category,disability,type,photos,criterias,values,result,review,district${isMap ? '&is_map=true' : ''}`);
      commit(SET_MARKER, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_MARKER, false);
    }
  },
  [GET_MARKER_REVIEW]: async ({commit}, {id,isMap}) => {
    commit(CHANGE_LOADING_MARKER_REVIEW, true);
    try {
      const response = await $http.get(`v2/units/${id}/review-results?include=children,criterias,questions,disability&is_map=${isMap}`);
      commit(SET_MARKER_REVIEW, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_MARKER_REVIEW, false);
    }
  },
  [SEND_MARKER_REVIEW]: async ({commit}, {id, payload}) => {
    commit(CHANGE_LOADING_SEND_MARKER_REVIEW, true);
    try {
      return await $http.post(`v2/units/${id}/review-results`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_SEND_MARKER_REVIEW, false);
    }
  },
  [GET_EMERGENCY_MARKERS]: async ({commit}, query) => {
    commit(CHANGE_LOADING_EMERGENCY_MARKERS, true);
    try {
      const response = await $http.get(`v1/critical-units?include=owner,checker,category,type,photos,criterias,values,review,result,district,review_results,criteria_value,disability`);
      commit(SET_EMERGENCY_MARKERS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_EMERGENCY_MARKERS, false);
    }
  },
  [GET_MARKER_FILTERS]: async ({commit}) => {
    commit(CHANGE_LOADING_MARKER_FILTERS, true);
    try {
      const response = await $http.get(`v1/filter?include=categories,type`);
      commit(SET_MARKER_FILTERS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_MARKER_FILTERS, false);
    }
  },
};

const mutations = {
  [SET_MARKERS](state, list) {
    state.markers = list;
  },
  [SET_MARKER_LIST](state, list) {
    list.forEach(el =>{
        if(!state.markerList.length || state.markerList.find(item => item.id === el.id) === undefined){
        state.markerList.push(el)
        }
      })
  },
  [SET_MAP_CHUNKS](state, list) {
    list.forEach(el=>{
      if(!state.mapChunks.length || state.mapChunks.find(item => item.id === el.id) === undefined){
        state.mapChunks.push(el)
        store.dispatch('map/GET_MARKERS_MAP', el.url)
      }
    })
  },
  [SET_LAST](state, data) {
    state.pageCount = data;
  },
  [SET_FORMATTED_MARKERS](state, list) {
    state.formattedMarkers = list;
  },
  [SET_EMERGENCY_MARKERS](state, list) {
    state.emergencyMarkers = list;
  },
  [CHANGE_LOADING_MARKERS](state, status) {
    state.markersLoading = status;
  },
  [CHANGE_LOADING_EMERGENCY_MARKERS](state, status) {
    state.emergencyMarkersLoading = status;
  },
  [CHANGE_LOADING_SEND_MARKER_REVIEW](state, status) {
    state.sendMarkerReviewLoading = status;
  },
  [SET_MARKER](state, list) {
    state.marker = list;
  },
  [CHANGE_LOADING_MARKER](state, status) {
    state.markerLoading = status;
  },
  [SET_MARKER_FILTERS](state, list) {
    state.markerFilters = list;
  },
  [CHANGE_LOADING_MARKER_FILTERS](state, status) {
    state.markerFiltersLoading = status;
  },
  [SET_MARKER_REVIEW](state, list) {
    state.markerReview = list;
  },
  [CLEAR_MARKER_REVIEW](state) {
    state.markerReview = null;
  },
  [CLEAR_MARKER](state) {
    state.marker = null;
  },
  [CHANGE_LOADING_MARKER_REVIEW](state, status) {
    state.markerReviewLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
