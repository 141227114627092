import {
  CHANGE_MAP_CONTROL,
} from '../mutation-types';

const state = {
  controlChangeTime: null, // need this for watching from different places
  controls     : [
    {id: 1, status: true, slug: 'search', icon: 'mdi-home-search-outline'},
    {id: 2, status: false, slug: 'route', icon: 'mdi-car'},
    {id: 3, status: false, slug: 'statistic', icon: 'mdi-chart-bar'},
    {id: 4, status: false, slug: 'complain', icon: 'mdi-message-alert-outline'},
  ]
};

const getters = {
  controls: state => state.controls,
  controlChangeTime: state => state.controlChangeTime,
  activeControlSlug: state => {
    const control = state.controls.find((el) => {
      return el.status === true
    });
    if (control) {
      return control.slug
    }
    return ''
  },
};
const mutations = {
  [CHANGE_MAP_CONTROL](state, control) {

    state.controlChangeTime = Date.now();

    if (typeof control === "string") {
      control = state.controls.find((el) => {
        return el.slug === control
      });
    }

    state.controls.map((el) => {
      el.status = false
    });
    control.status = true;
  },
};

export default {
  state,
  getters,
  mutations,
};
