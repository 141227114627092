import {ADMIN_TYPE} from "./constants";

const DAY_MILLISECONDS = 1000 * 60 * 60 * 24;

export function getRelativeTime(timestamp, locale) {
    const rtf = new Intl.RelativeTimeFormat(locale, {
        numeric: 'auto',
    });
    const daysDifference = Math.round(
    (timestamp - new Date().getTime()) / DAY_MILLISECONDS,
    );

    return rtf.format(daysDifference, 'day');
}

// ARC fix: https://www.w3.org/TR/WCAG20/#name-role-value
export function fixArc(delay = 1000) {
    setTimeout(() => {
        [...document.getElementsByClassName('v-item-group')].forEach((item, index) => {
            item.setAttribute("aria-label", "alttext" + index);
        })
        document.querySelectorAll('[aria-expanded]').forEach((item) => {
            item.setAttribute("role", "button");
        })
        document.querySelectorAll('[aria-owns]').forEach((item) => {
            item.setAttribute(
            "aria-owns",
            item.getAttribute('aria-owns').replace('list', 'input')
            );
        })
        document.querySelectorAll('[aria-hidden="true"]').forEach((item) => {
            item.setAttribute("aria-hidden", "false");
        });

        [...document.getElementsByTagName('svg')].forEach((item, index) => {
            item.setAttribute('role', 'img');
            item.setAttribute("name", "name" + index);
            item.setAttribute("aria-label", "alttext" + index);
        })
    }, delay);
}


export function handleTabNavigation() {

    const body = document.body;
    if (body) {
        body.addEventListener('click', () => {
            if (body.classList.contains('tab-selection-mode')) {
                body.classList.remove('tab-selection-mode');
            }
        })
        body.addEventListener('keydown', (event) => {
            if (event.code === 'Tab') {
                if (!body.classList.contains('tab-selection-mode')) {
                    body.classList.add('tab-selection-mode');
                }
            } else {
                // should we remove selection mode in that case? =)
            }
        })

    }
}

export function defineUserRole(user) {

    let {parentId, role} = user
    let userRole = role && role.data.slug ? role.data.slug : null;

    if (role.data.slug === 'admin_units'){
        userRole = parentId === null ? ADMIN_TYPE.ADMIN_1 : ADMIN_TYPE.ADMIN_2;
    } else if (role.data.slug === 'admin_0'){
        userRole = ADMIN_TYPE.ADMIN_0
    } else if (role.data.slug === 'admin_0_1') {
        userRole = ADMIN_TYPE.ADMIN_0_1
    }
    return userRole
}




