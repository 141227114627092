import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import Toasted from 'vue-toasted';
// import VueAnimate from 'vue-animate-scroll';
// import VueFacebookPixel from 'vue-analytics-facebook-pixel'


import 'moment/locale/en-gb'
import 'moment/locale/lt'



//use
Vue.use(Toasted, {
  duration: 3000
});
// Vue.use(VueAnimate);
Vue.use(VueTheMask);
// Vue.use(VueFacebookPixel)