import Vue from 'vue';
import Loader from '@/components/misc/loader.vue';
import PopupWrap from '@/components/misc/popup-wrap.vue';
import mainButton from '@/components/main-button/index.vue';
import smallLoader from '@/components/misc/small-loader.vue';

Vue.component('small-loader', smallLoader);
Vue.component('loader', Loader);
Vue.component('popup-wrap', PopupWrap);
Vue.component('main-button', mainButton);
Vue.component('v-pagination', window['vue-plain-pagination']);
