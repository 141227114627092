import {mapActions, mapGetters} from "vuex";

import Popup from "@/components/popup/index.vue";

export default {
  name: "main-footer",

  components: {
    Popup
  },
  data(){
    return{

    }
  },
  computed: {
    ...mapGetters({
      variables: 'setting/variables'
    }),
    getPhone(){
      if(this.variables){
        if(this.variables.length){
          return this.variables.find(el => el.key === 'phone')
        } else return null
      } else return null
    },
    getEmail(){
      if(this.variables){
        if(this.variables.length){
          return this.variables.find(el => el.key === 'email')
        } else return null
      } else return null
    }
  },
  created() {
    this.fetchVariables()
  },
  methods: {
    ...mapActions({
      fetchVariables: 'setting/GET_VARIABLES'
    }),
  }
}