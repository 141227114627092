import {mapMutations, mapGetters, mapActions} from "vuex";
import changeHtmlLang from "@/utils/change-html-lang";
import MapControlButtons from "@/components/map-control-buttons/index.vue";
import {CHANGE_EASY, CHANGE_SIGN} from "@/store/mutation-types";
export default {
  name: "main-header",
  data(){
    return{
      burger: false,
    }
  },
  components: {
    MapControlButtons,
  },
  watch: {
    '$route.name': {
      immediate: true,
      handler(newVal){
        if(newVal){
          this.burger = false
        }
      }
    }
  },
  created() {
    this.fetchVariables()
  },
  computed: {
    ...mapGetters([
      'languages',
      'currentLanguage',
    ]),
    ...mapGetters({
      easyToRead: 'system/easyToRead',
      signLanguage: 'system/signLanguage',
      variables: 'setting/variables',
    }),
    getAboutEasy(){
      if(this.variables){
        if(this.variables.length){
          return this.variables.find(el => el.key === 'about_us_sign_language')
        } else return null
      } else return null
    },
    getAboutPdf(){
      if(this.variables){
        if(this.variables.length){
          return this.variables.find(el => el.key === 'about_us_easy_to_read')
        } else return null
      } else return null
    },
    getCriteriaEasy(){
      if(this.variables){
        if(this.variables.length){
          return this.variables.find(el => el.key === 'criteria_sign_language')
        } else return null
      } else return null
    },
    getCriteriaPdf(){
      if(this.variables){
        if(this.variables.length){
          return this.variables.find(el => el.key === 'criteria_easy_to_read')
        } else return null
      } else return null
    }
  },
  methods: {
    ...mapMutations({
      dropFilter: 'system/DROP_FILTER',
      // changeLoginPopup:'popups/CHANGE_LOGIN_POPUP'
      changeEasy: 'system/CHANGE_EASY',
      changeSign: 'system/CHANGE_SIGN',
    }),
    ...mapActions({
      fetchVariables: 'setting/GET_VARIABLES'
    }),
    redirectToAbout(){
      this.$router.push(this.$localize({name: 'about'})).catch(()=>{console.log()})
    },
    changeLanguage(language){
      const selectedLanguage = this.languages.find(item => item.key === language.key);

      this.$setLanguage(selectedLanguage.code);
      localStorage.setItem('localization', selectedLanguage.key);
      changeHtmlLang(selectedLanguage.key);

      this.showLangList = false;

      setTimeout(() => {
        window.location.reload();
      }, 3000)
    },
    isRoute(page) {
      return this.$route.name === page;
    },
    redirect(){
      this.dropFilter(true)
      this.$router.push(this.$localize({name: 'main'})).catch(()=>{console.log()})
    },
    printScreen(){
      window.print();
    }
  }
}