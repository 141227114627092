//utils
import './utils/plugins'
import './utils/translations'
import './utils/global-components'
//
import moment from 'moment';

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueHead from 'vue-head'
import Vuetify from "vuetify";
import vuetify from '@/plugins/vuetify'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import {handleTabNavigation} from '@/helpers/functions';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


Vue.use(Vuetify);

// moment
Vue.prototype.moment = moment


Vue.use(VueHead)
//vue configs
Vue.config.productionTip = false;

//set token after refresh page
if (localStorage.getItem('user_token')) {
  store.commit(`auth/SET_TOKEN`, localStorage.getItem('user_token'));
}



const app = new Vue({
  router,
  store,
  render: h => h(App),
  vuetify,
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
});

Vue.initI18nManager().then(() => {
  app.$mount('#app')
});

handleTabNavigation();

