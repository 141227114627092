import {
  ASIDE_REGISTER,
  ASIDE_CLOSE,
} from '../mutation-types';

const state = {
  pages: [],
  maxPageSize: 100,
  pagePadding: 25,
};

const getters = {
  pages: state => state.pages,
  maxPageSize: state => state.maxPageSize,
};

const mutations = {
  [ASIDE_REGISTER](state, object) {
    object.maxWidth = state.maxPageSize - state.pagePadding * state.pages.length;
    object.index = 2000 + state.pages.length;
    state.pages.push(object);
  },
  [ASIDE_CLOSE](state, object) {
    let close = false;

    for (let i = 0; i < state.pages.length; i++) {
      let page = state.pages[i];
      if (page === object) {
        close = true;
      }

      if (close) {
        page.hidden = true;
        setTimeout(() => {page.$emit('close');}, 500);
        state.pages.splice(i, 1);
        i--;
      }
    }
  },
};

export default {
  state,
  getters,
  mutations,
};
